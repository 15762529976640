import { TSelectOption } from './Select'

export const mockDays: TSelectOption[] = [
  { key: 1, value: 'вторник 11 мая' },
  { key: 2, value: 'среда 12 мая' },
  { key: 3, value: 'четверг 13 мая' },
]
export const mockTimes: TSelectOption[] = [
  { key: 1, value: '8:00-12:00' },
  { key: 2, value: '12:00-18:00' },
  { key: 3, value: '18:00-20:00' },
]
export const mockRegions: TSelectOption[] = [
  { key: 1, value: 'Москва' },
  { key: 2, value: 'Московская область' },
]

const days = []
for (let i = 1; i <= 31; i++) {
  days.push(i)
}

const dateOfBirth = () => {
  const max = new Date().getFullYear() - 16
  const min = max - 66
  const years = []

  for (let i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}

export const mockDates = {
  days,
  month: [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
  ],
  years: dateOfBirth(),
}
