import { useEffect } from 'react'

import Header from 'particials/Header'
import Footer from 'particials/Footer'

import { LayoutProps } from './Layout.props'

export default function Layout({ children, classNames }: LayoutProps): JSX.Element {
  useEffect(() => {
    if (classNames) {
      document.body.classList.add(classNames)
    }
    return () => {
      if (classNames) {
        document.body.classList.remove(classNames)
      }
    }
  }, [classNames])

  return (
    <>
      <Header />

      {children}

      <Footer />
    </>
  )
}
