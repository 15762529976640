import React, { useState, useRef } from 'react'

import useOnClickOutside from 'hooks/useOnClickOutside'

interface Props {
  className?: string
  title: {
    text: string
    className?: string
  }
  children: React.ReactNode
}

const DropDown = (props: Props) => {
  const ref = useRef(null)
  const [open, setOpen] = useState(false)

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setOpen(!open)
  }

  const handleClickOutside = () => {
    setOpen(false)
  }

  useOnClickOutside(ref, handleClickOutside)

  return (
    <li className={props.className} ref={ref}>
      <a className={props.title.className} onClick={handleClick}>
        {props.title.text}
      </a>
      {open && props.children}
    </li>
  )
}

export default DropDown
