import { useState, useRef, useEffect } from 'react'
import cn from 'classnames'

import useOnClickOutside from 'hooks/useOnClickOutside'
import { TSelectOption, TStringOrNumber } from './Select'
import { mockRegions } from './mocks'

const cities: TSelectOption[] = mockRegions

interface Props {
  secondary?: boolean
  defaultValue?: TStringOrNumber
}
export default function CitySelect({ secondary, defaultValue }: Props) {
  const ref = useRef(null)
  const _defaultValue: TStringOrNumber = defaultValue || cities[0]?.key
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<TStringOrNumber>(_defaultValue)

  const wrapperClassName = cn('city', { 'd-md-none': secondary })
  const bodyClassName = cn('drop', 'animate__animated', 'animate__faster', {
    animate__fadeIn: open,
  })

  useEffect(() => {
    const cityKey = localStorage.getItem('cityKey')
    if (cityKey) {
      setValue(JSON.parse(cityKey))
    } else {
      setValue(cities[0]?.key)
    }
  }, [])

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setOpen(!open)
  }

  const handleClickOutside = () => {
    setOpen(false)
  }

  const changeCity = (cityKey: TStringOrNumber) => () => {
    localStorage.setItem('cityKey', JSON.stringify(cityKey))
    setOpen(false)
    setValue(cityKey)
  }

  useOnClickOutside(ref, handleClickOutside)

  return (
    <div className={wrapperClassName} ref={ref}>
      <span className="current" onClick={handleClick}>
        {cities.find((item) => item.key === value)?.value}
      </span>
      {open && (
        <div className={bodyClassName}>
          {cities.map((city) => (
            <a onClick={changeCity(city.key)} key={city.key}>
              {city.value}
            </a>
          ))}
        </div>
      )}
    </div>
  )
}
