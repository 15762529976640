import Head from 'next/head'
import React, { FunctionComponent } from 'react'
import Layout from '../particials/Layout'

const defaultMeta = {
  description: 'Химчистка с доставкой',
  keywords: 'Химчистка с доставкой, Химчистка, Восстановление одежды',
  title: 'Химчистка с доставкой',
}

export const withLayout = <T extends Record<string, unknown>>(Component: FunctionComponent<T>, classNames?: string) => {
  return function withLayoutComponent(props: T): JSX.Element {
    const meta = props.meta as typeof defaultMeta
    const { title, description, keywords } = meta || defaultMeta
    console.log('meta', meta)

    return (
      <>
        <Head>
          <title>{title}</title>
          <meta name="description" content={description} key="description" />
          <meta name="keywords" content={keywords} key="keywords" />
        </Head>
        <Layout classNames={classNames}>
          <Component {...props} />
        </Layout>
      </>
    )
  }
}
