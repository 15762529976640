import { TService } from "types/Service";

type TQueryField = string | string[] | undefined

export function filterServicesByVisibility(services: TService[] | undefined = []): TService[] {
  return services?.filter(s => s.isVisible)
}

export function filterServicesByName(services: TService[] | undefined = [], value: TQueryField = ''): TService[] {
  if (!value) return services

  return services?.filter((s) =>
    s.name.toLowerCase()?.includes(((value as string) ?? '').toLowerCase())
  )
}

export function filterServicesById(services: TService[] | undefined = [], serviceId: TQueryField): TService[] {
  if (!serviceId) return services

  return services?.filter((s) =>
    s.id === Number(serviceId)
  )
}