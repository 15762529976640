import NextLink from 'next/link'

import { useAppSelector } from 'hooks/useRedux'
import { selectUser } from 'store/features/authSlice'
import { ROUTES } from 'constants/routes'
import { PHONE_NUMBER } from 'constants/PhoneNumber'

function scrollTop() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

export default function Footer() {
  const user = useAppSelector(selectUser)
  const footerDate = new Date()

  return (
    <>
      <div className="on-top">
        <a className="scrollto scroll-top" onClick={scrollTop}>
          <span></span>Наверх
        </a>
      </div>
      <footer>
        <div className="container">
          <div className="inner-block">
            <div className="line-1">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <div className="logo d-none d-lg-block">
                    <NextLink href={ROUTES.home}>
                      <img src="/images/logo.svg" alt="" title="" />
                    </NextLink>
                  </div>
                  <div className="mail">
                    <a href="mailto:info@4b.ru">info@4b.ru</a>
                  </div>
                  <div className="phone">
                    <a href={`tel:${PHONE_NUMBER.RAW}`}>{PHONE_NUMBER.FORMATED}</a>
                  </div>
                </div>
                <div className="col-12 col-sm-3 d-none d-lg-block">
                  <ul className="nav flex-column">
                    {/* <li className="nav-item">
                                <a className="nav-link" href="uslugi.html">Заказать услуги</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="cart.html">Корзина</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="order.html">Оформмить заказ</a>
                            </li> */}
                    <CustomLink href={ROUTES.prices}>Цены</CustomLink>
                    <CustomLink href={ROUTES.points}>Пункты приема</CustomLink>
                    <CustomLink href={ROUTES.stock}>Акции</CustomLink>
                    <CustomLink href={ROUTES.bussiness}>Для бизнеса</CustomLink>
                  </ul>
                </div>
                <div className="col-12 col-sm-3 d-none d-lg-block">
                  <ul className="nav flex-column">
                    {user ? (
                      <CustomLink href={ROUTES.profile}>Профиль</CustomLink>
                    ) : (
                      <>
                        <CustomLink href={ROUTES.signUp}>Регистрация</CustomLink>
                        <CustomLink href={ROUTES.signIn}>Личный кабинет</CustomLink>
                      </>
                    )}
                  </ul>
                </div>
                <div className="col-12 col-lg-3">
                  <ul className="nav flex-column  d-none d-lg-block"></ul>
                  <div className="social">
                    {/* <a>
                      <img src="/images/social/social_fb.svg" alt="" title="" />
                    </a> */}
                    {/* <a>
                      <img src="/images/social/social_insta.svg" alt="" title="" />
                    </a> */}
                    {/* <a>
                      <img src="/images/social/social_youtube.svg" alt="" title="" />
                    </a> */}
                    <a href="https://wa.me/79689259317">
                      <img src="/images/social/social_whatsapp.svg" alt="" title="" />
                    </a>
                    <a href="https://t.me/chisto_4bru">
                      <img src="/images/social/social_tg.png" alt="" title="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="copy-block">
              <div className="row">
                <div className="col-12 col-sm-6 copy">© {footerDate.getFullYear()} 4b.ru</div>
                <div className="col-12 col-sm-6 text-right">
                  <NextLink href={ROUTES.policy}>Обработка персональных данных</NextLink>
                </div>
              </div>
            </div>
            <div className="text">
              <p>
                Предлагаем услуги прачечной и химчистки в Москве и Московской области по выгодной цене. Химчистка одежды
                и стирка других вещей с доставкой на дом позволит Вам не тратить лишнего времени и сократить расходы без
                потери качества ухода за вещами. Доверьте химчистку опытным профессионалам «4b.ru» – мы обеспечим
                деликатный подход к каждой вещи и сохраним новизну.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

interface Props {
  href: string
  children: React.ReactNode
}
const CustomLink = (props: Props) => {
  return (
    <li className="nav-item">
      <NextLink href={props.href}>
        <a className="nav-link">{props.children}</a>
      </NextLink>
    </li>
  )
}
