import { useState, useRef } from 'react'
import { useRouter } from 'next/router'
import { serviceAPI } from 'services'
import { TService } from 'types/Service'
import { filterServicesByName } from 'helpers/filters'
import useOnClickOutside from 'hooks/useOnClickOutside'

interface Props {
  isGlobal?: boolean
  placeholder?: string
}
export default function SearchInput({ isGlobal, placeholder = 'Введите название услуги' }: Props) {
  const ref = useRef(null)
  const router = useRouter()
  const [value, setValue] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)
  const services = serviceAPI.useGetQuery()
  const pathname = isGlobal ? '/search' : router.pathname

  function handleInput(event: React.FormEvent<HTMLInputElement>): void {
    const newValue = event.currentTarget.value
    setValue(newValue)
    if (newValue.length > 0) {
      setOpen(true)
    } else {
      setOpen(false)
      router.push({ pathname }, undefined, { scroll: false })
    }
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { serviceId, ...spreadQuery } = router.query
    const query = isGlobal ? { search: value } : { ...spreadQuery, search: value }
    router.push({ pathname, query }, undefined, { scroll: false })
    setOpen(false)
  }

  const onClickFindedService = (service: TService) => () => {
    setOpen(false)
    setValue(service.name)
    router.push({ pathname, query: { search: service.name, serviceId: service.id } }, undefined, { scroll: false })
  }

  const findedServices: TService[] = filterServicesByName(services?.data, value)

  useOnClickOutside(ref, () => setOpen(false))

  return (
    <div className="search" ref={ref}>
      <form onSubmit={onSubmit}>
        <input
          autoComplete="off"
          value={value}
          onInput={handleInput}
          placeholder={placeholder}
          name="search"
        />
        <button type="submit" className="btn-search"></button>
        {open && findedServices.length > 0 && (
          <ul className="finded_container">
            {findedServices.map((service) => (
              <li key={service.id} onClick={onClickFindedService(service)}>
                {service.name}
              </li>
            ))}
          </ul>
        )}
      </form>
    </div>
  )
}
