import Link from 'next/link'
import cn from 'classnames'

import { useState } from 'react'

import { selectUser } from 'store/features/authSlice'
import { useAppSelector } from 'hooks/useRedux'
import { serviceAPI } from 'services'
import { selectBasketItems } from 'store/features/basketSlice'

import DropDown from 'components/DropDown'
import CitySelect from 'components/selects/CitySelect'
import SearchInput from 'components/SearchInput'
import { ROUTES } from 'constants/routes'
import { PHONE_NUMBER } from 'constants/PhoneNumber'
import moment from 'moment'

const getClassName = (...classNames: string[]) => cn(...classNames, 'animate__animated animate__fadeIn animate__faster')

export default function Header() {
  const { data } = serviceAPI.useGetQuery()
  const basketItems = useAppSelector(selectBasketItems)
  const user = useAppSelector(selectUser)
  const [isScrolled] = useState<boolean>(true)
  const { data: localDate, isLoading } = serviceAPI.useLocalDateQuery(moment().format("YYYY-MM-DD"))

  const list = data?.filter((service) => basketItems[service.id]) || []
  const orderSum = list.reduce((a, b) => {
    return a + b.price * basketItems[b.id]
  }, 0)
  const orderCount = list.reduce((a, b) => {
    return a + basketItems[b.id]
  }, 0)

  // useEffect(() => {
  //   const onScroll = debounce(() => {
  //     if (window.scrollY > 0) {
  //       setScrolled(true)
  //     } else {
  //       setScrolled(false)
  //     }
  //   }, 100)
  //   document.addEventListener('scroll', onScroll)
  //   return () => document.removeEventListener('scroll', onScroll)
  // }, [])

  return (
    <header
      className={cn({
        slide: isScrolled,
      })}
    >
      <div className="container container-big">
        <div className="row">
          <div className="col-3 col-lg-2">
            <div className="logo">
              <Link href={ROUTES.home}>
                <a>
                  <img src="/images/logo.svg" alt="" title="" />
                </a>
              </Link>
            </div>
          </div>
          <div className="col-9 col-lg-10">
            <div className="header-line1">
              <CitySelect />
              <div className="phone">
                <Link href={ROUTES.points}>
                  <a style={{marginRight: '20px'}}>Пункты приема</a>
                </Link>
                <a href={`tel:${PHONE_NUMBER.RAW}`}>{PHONE_NUMBER.FORMATED}</a>
                <span className="time">{isLoading ? '' : (localDate ? `${moment(localDate?.from, "HH:mm:ss").add(3, 'h').format("HH:mm")} - ${moment(localDate?.to, "HH:mm:ss").add(3, 'h').format("HH:mm")}` : 'Выходной')}</span>
                <Link href={ROUTES.callback}>
                  <a className="time slide_d_none">Заказать обратный звонок</a>
                </Link>
              </div>
            </div>
            <div className="header-line2">
              <div className="cart">
                <Link href={ROUTES.basket}>
                  <a>
                    <div className="cart-total">
                      {orderSum} <span className="rub" />
                    </div>
                    <div className="cart-icon">
                      <div className="cart-count">{orderCount}</div>
                    </div>
                  </a>
                </Link>
              </div>

              {user ? (
                <>
                  {/* <Link href="/logout"><a className="link enter">Выйти</a></Link> */}
                  <Link href={ROUTES.profile}>
                    <a className="link enter text-center">{user?.name}</a>
                  </Link>
                </>
              ) : (
                <Link href={ROUTES.signIn}>
                  <a className="link enter text-center">Войти</a>
                </Link>
              )}

              <div className="h-cnt">
                <SearchInput isGlobal placeholder="Поиск по каталогу" />
                <ul className="nav">
                  <DropDown className="slide-menu" title={{ text: '', className: 'slide-menu-link' }}>
                    <div className={getClassName('drop-menu')}>
                      <CitySelect secondary />
                      <Link href={ROUTES.prices}>Услуги</Link>
                      <Link href={ROUTES.points}>Пункты приема</Link>
                      <Link href={ROUTES.stock}>Акции</Link>
                      <Link href={ROUTES.bussiness}>Для бизнеса</Link>
                      <Link href={ROUTES.aboutUs} >О нас</Link>
                      <Link href={ROUTES.qa} >Вопросы и ответы</Link>
                      <Link href={ROUTES.reviews}>Отзывы</Link>

                      {user ? (
                        <Link href={ROUTES.profile}>Профиль</Link>
                      ) : (
                        <>
                          <Link href={ROUTES.signUp}>Регистрация</Link>
                          <Link href={ROUTES.signIn}>Личный кабинет</Link>
                        </>
                      )}
                    </div>
                  </DropDown>

                  {/* <DropDown title={{ text: 'Заказать', className: 'nav-link' }}>
                    <div className={getClassName('drop-menu', 'zakazat-menu')}>{serviceLinks}</div>
                  </DropDown> */}
                  {/* <DropDown title={{ text: 'О нас', className: 'nav-link' }}>
                    <div className={getClassName('drop-menu', 'onas-menu')}>{infoLinks}</div>
                  </DropDown> */}
                  <li>
                    <Link href={ROUTES.points}>
                      <a className="nav-link text-center">Пункты приема</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="phone d-block d-md-none">
          <a href={`tel:${PHONE_NUMBER.RAW}`}>{PHONE_NUMBER.FORMATED}</a>
        </div>
      </div>
    </header>
  )
}
